<!--电能设置--计量对象组---->
<template>
  <div id="energyComparison" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div style="padding-right:5px;">
        <div class="header-title">
          {{ $t("energy.enedata018") }}
        </div>
      </div>
    <a-row style="height: calc(100% - 30px);">
        <a-col :span="4" style="height:100%;">
            <div class="point-tree-box">
                <a-menu
                    class="point-tree"
                    mode="inline"
                    :inline-collapsed="collapsed"
                    :open-keys.sync="openKeys"
                    :default-open-keys="['0']"
                    @click="handleClick"
                >
                    <template v-for="item in energyComparisonList">
                    <a-menu-item v-if="!item.children" :key="item.no">
                        <a-icon type="pie-chart" />
                        <span>{{ item.text }}</span>
                    </a-menu-item>
                    <sub-menu v-else :key="item.no" :menu-info="item" :fatherMethod="deleteClick"/>
                    </template>
                </a-menu>
            </div>
        </a-col>
        <a-col :span="20" >
            <div class="display-between">
                <!-- 条件选择2 -->
                <div class="condition">
                    <div class="display-column">
                    <!-- 类型 -->
                    <span>{{ $t("energy.enedata002") }}</span>
                    <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                        <a-radio-button  v-for="(itme, index) in this.rptKinds"
                            :key="index"
                            :value="itme.no"> {{ itme.text }}</a-radio-button>
                    </a-radio-group>
                    </div>
                    <div class="display-column" v-if="rpt=='3'">
                    <!-- 目标年度 -->
                    <span>{{ $t("energy.enedata005") }}</span>
                    <a-select v-model="year" style="width: 120px" @change="yearChange">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                        </a-select-option>
                    </a-select>
                    </div>
                    <div class="display-column" v-if="rpt=='3'">
                        <span>{{$t("energy.enedata398")}}</span>
                        <a-switch v-model="msel" @change="mselChange" :style="{'width':'60px','margin':'5px 0'}" />
                    </div>
                    <div class="display-column" v-if="rpt=='3'">
                        <span>{{$t("energy.enedata399")}}</span>
                        <a-select v-model="selMonth" style="width: 120px" :disabled="defaultStatistic==0" >
                        <a-select-option
                            v-for="(itme, index) in this.monthList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="display-column" v-if="rpt=='2'">
                        <!-- 目标年度 -->
                        <span>{{ $t("energy.enedata017") }}</span>
                        <a-month-picker v-model="ecmonth" :format="monthFormat" />
                    </div>
                    <div class="display-column" v-if="rpt=='1'">
                    <!-- 目标年度 -->
                        <span>{{ $t("energy.enedata017") }}</span>
                        <a-date-picker  v-model="ecdate" :format="dateFormat" />
                    </div>

                    <div class="display-column">
                        <!-- 显示对象 -->
                        <span>{{ $t("energy.enedata004") }}</span>
                        <a-radio-group v-model="chartKind" button-style="solid" @change="drawChart">
                            <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                            <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                            <a-radio-button value="pie">{{ $t("energy.enedata015") }}</a-radio-button>
                        </a-radio-group>
                    </div>
                </div>
                <a-button type="primary" @click="loadEnergyComparison(1)">{{ $t("energy.enedata006") }}</a-button>
            </div>

            <div style="background:#7682CE;">
                <template> 
                  <a-carousel arrows>
                    <div slot="prevArrow"  class="custom-slick-arrow" style="left: 10px;zIndex: 1;">
                      <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                      <a-icon type="right-circle" />
                    </div>

                    <div style="height:100%;">
                      <div class="chart-box">
                        <div id="energyComparisonChart" :style="myChartSize" ></div>
                      </div>

                    </div>
                      <div style="height:100%;">
                        <h2 style="text-align: left;">{{mobj==null?"":mobj.groupName}}</h2>
                        <div class="table-box">
                            <a-table :style="{width: '100%', height: '400px',padding:'10px'}"
                                :columns="columns"
                                :data-source="tableData"
                                :pagination="pagination"
                                :scroll="xscroll"
                                size="small"
                            />
                          </div>
                      </div>
                  </a-carousel>
                </template>
            </div>

            <!-- 图表 -->
            <!-- <div class="ec-box" style="overflow: auto;display:none;">
                <div class="histogram">
                    <div class="chart-box">
                        <div id="energyComparisonChart" :style="{width: '100%', height: '400px'}" ></div>                
                    </div>
                </div>
                <div class="histogram">
                    <div class="chart-box">
                        <a-table :style="{width: '100%', height: '400px',padding:'10px'}"
                            :columns="columns"
                            :data-source="tableData"
                            :pagination="pagination"
                            :scroll="xscroll"
                            size="small"
                        />
                    </div>
                </div>
            </div> -->
        </a-col>
    </a-row>
    

  </div>
</template>

<script>
import { getEnergyComparisonData  } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import moment from "moment";

const colors = ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'];
const colors1 = ['rgba(128, 255, 165)', 'rgba(0, 221, 255)' , 'rgba(55, 162, 255)', 'rgba(255, 0, 135)', 'rgba(255, 191, 0)'];
const colors2 = ['rgba(1, 191, 236)'  , 'rgba(77, 119, 255)', 'rgba(116, 21, 219)', 'rgba(135, 0, 157)', 'rgba(224, 62, 76)'];
const colors3=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];

export default {
  components: {
    'sub-menu': SubMenu,
  },
  data() {
     return {
      collapsed:false,
      visible:false,
      loading:false,
      rootSubmenuKeys: ["0"],
      openKeys: ["0"],
      energyComparisonList:[],
      chartData:null,
      tableData:[],
      columns:[],
      energyComparisonId:'',
      yearList:[],
      year:0,
      rptKinds:[],
      rpt:'3',      
      ycategorys:[],
      mcategorys:[],
      dcategorys:[],
      date:'',
      month:'',
      dateFormat: 'YYYY/MM/DD',
      monthFormat: 'YYYY/MM',
      chartKind:'line',
      pagination:false,
      xscroll: { x: 700 },
      mobj:null,
      ecmonth:moment(),
      ecdate:moment(),
      msel:false,
      defaultStatistic:0,
      selMonth:"1",
      monthList:[],
      myChartSize:{width: '100%', height: '500px'},
      fiscalYear:undefined,
      energyComparisonChart:undefined,
    };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadEnergyComparison(0);
        this.tableData=[];
       // columns:[],
    }
  },
  computed: {
    
  },
  watch: {
    openKeys(val) {
      console.log('openKeys', val);
    },
    // "$store.getters.clientId":{
    //   handler(val){
    //     console.log(val);
    //     if(val!==""){
    //       this.loadEnergyComparison(0);
    //     }
    //   },
    //   deep:true,
    //   immediate:true,
    // }
  },
  methods: {
    drawChart(){
        if(this.chartData){
            let title = this.mobj.groupName;
            let unit = "";
            let legends = new Array();
            let categories = new Array();
            let list = new Array();
            let cat = "";
            for (let index = 0; index < this.chartData.length; index++) {
                const element = this.chartData[index];
                if(index == 0){
                    categories = element.categories;
                    unit = element.unit;
                    cat = element.xunit;
                }
                legends.push(element.name);
                let item = {
                    name:element.name,
                    data:element.data,
                }
                list.push(item);
            }
            if(this.chartKind === 'line'){
                this.drawLine(title,unit,legends,categories,list,this.chartKind );
            }else if(this.chartKind === 'bar'){
                this.drawLine(title,unit,legends,categories,list,this.chartKind );
            }else if(this.chartKind === 'pie'){
                this.drawPie(title,cat,unit,legends,categories,list);
            }
        }
    },
    drawPie(title,cat,unit,legends,categories,list ){
        let ds = new Array();
        let cats = new Array();
        cats.push(cat);
        for (let index = 0; index < categories.length; index++) {
            cats.push(categories[index]);
        }
        ds.push(cats);
        let series = new Array();
        
        for (let index = 0; index < this.chartData.length; index++) {
            const element = this.chartData[index];
            let dk = new Array();
            dk.push(element.name);
            for (let k = 0; k < element.data.length; k++) {
                dk.push(element.data[k]);
            }
            ds.push(dk);

            let s =  {type: 'line', smooth: true, seriesLayoutBy: 'row', emphasis: {focus: 'series'}};
            series.push(s);
        }


        let pie = {
            type: 'pie',
            id: 'pie',
            radius: '28%',
            center: ['50%', '35%'],
            emphasis: {focus: 'data'},
            label: {
                formatter: '{b}: {@'+cats[1]+'} '+unit+' ({d}%)'
            },
            encode: {
                itemName: cats[0],
                value: cats[1],
                tooltip: cats[1]
            }
        };
        series.push(pie);

        if(this.energyComparisonChart){
            this.energyComparisonChart.dispose();
        }
        this.energyComparisonChart = this.$echarts.init(document.getElementById("energyComparisonChart"));
        let echart = this.energyComparisonChart;
        setTimeout(function () {
            let  option = {
                color: colors3,
                backgroundColor: '#ffffff',
                legend: {},
                tooltip: {
                    trigger: 'axis',
                    showContent: false
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                dataset: {
                    source: ds
                },
                xAxis: {type: 'category'},
                yAxis: {gridIndex: 0,name:unit},
                grid: {top: '55%'},
                series: series
            };

            echart.on('updateAxisPointer', function (event) {
                var xAxisInfo = event.axesInfo[0];
                if (xAxisInfo) {
                    var dimension = xAxisInfo.value + 1;
                    let opt = {
                        series: {
                            type:"pie",
                            id: 'pie',
                            label: {
                                formatter: '{b}: {@[' + dimension + ']} '+unit+' ({d}%)'
                            },
                            encode: {
                                value: dimension,
                                tooltip: dimension
                            }
                        }
                    };
                    echart.setOption(opt);
                }
            });
            
            echart.setOption(option, true);

        },10);
    },
    drawLine(title,unit,legends,categories,list,type){
       
        let arr = new Array();
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            let s = {
                    name: element.name,
                    type: type,
                    stack: '总量',
                    smooth: true,
                    lineStyle: {
                        width: 0
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: colors1[index]
                        }, {
                            offset: 1,
                            color: colors2[index]
                        }])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: element.data
                };
            arr.push(s);
        }
        let option = {
            backgroundColor: '#ffffff',
            title: {
                text: title,
                textStyle: {
                  color: colors3[0],
                },
            },
            color: type=='line'?colors:colors3,
            // title: {
            //     text: title
            //     //,subtext:unit
            // },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: legends,
                orient: 'vertical',
                left: 'right',
                top:'10%',
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            grid: {
                left: '3%',
                right: '20%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: (type==='bar'),
                    data: categories
                }
            ],
            yAxis: [
                {
                    type: 'value',name:unit
                    
                }
            ],
            series: arr
        };

        // 基于准备好的dom，初始化echarts实例
        if(this.energyComparisonChart){
            this.energyComparisonChart.dispose();
        }
        this.energyComparisonChart = this.$echarts.init(document.getElementById("energyComparisonChart"));
        option && this.energyComparisonChart.setOption(option, true);
        this.resizeChart();
    },
    rptChange(e){
        let v = parseInt(e.target.value);
        console.log('rptChange',v);
        let now = moment();
        
        if(v==1){
            console.log('rptChange',now.format("YYYY/MM/DD"));
            this.date = now.format("YYYY/MM/DD");
            this.ecmonth = now;
        }else if(v==2){
            console.log('rptChange',now.format("YYYY/MM"));
            this.date = now.format("YYYY/MM");
            this.ecdate = now;
        }

        this.drawDefultChart();

        this.chartData = null;
        this.columns=[];
        this.tableData=[];
    },
    yearChange(e){
        console.log('yearChange', e);
        if( this.energyComparisonId != ''){
            this.loadEnergyComparison(1);
        }
    },
    handleClick(e) {
      console.log('handleClick', e);
      this.energyComparisonId = e.key;
      
      console.log('energyComparisonId', this.energyComparisonId);
      this.loadEnergyComparison(1);
    },
    deleteClick(){

    },
    titleClick(e) {
      console.log('titleClick', e);
    },
    toggleCollapsed() {
    //   this.collapsed = !this.collapsed;
    },
    loadEnergyComparison(action){
      let params =  {
          action:action,
          clientId: this.$store.getters.clientId,
          siteId: this.$route.query.id,
          sitegrId: this.$store.getters.sitegrId,
          tenantId: 0,
      };
      if(action==1){
          params.measureGroup = this.energyComparisonId;
          params.reportType = this.rpt;
          if(this.rpt=='3'){
              params.year = this.year;
              params.month = this.selMonth;
              params.defaultStatistic = this.defaultStatistic;
          }else if(this.rpt=='2'){
              params.dataTime = this.ecmonth.format('YYYY/MM');
          }else if(this.rpt=='1'){
              params.dataTime = this.ecdate.format('YYYY/MM/DD');
          }
      }

      console.log("loadenergyComparison Params",params);
      this.loading = true;
      getEnergyComparisonData(params)
      .then((res) => {
        console.log("loadenergyComparison Result",res);
        if(action==1){
            let list = new Array();
            if(res.data.statisticsGroupPoint1){
                list.push(res.data.statisticsGroupPoint1);
            }
            if(res.data.statisticsGroupPoint2){
                list.push(res.data.statisticsGroupPoint2);
            }
            if(res.data.statisticsGroupPoint3){
                list.push(res.data.statisticsGroupPoint3);
            }
            if(res.data.statisticsGroupPoint4){
                list.push(res.data.statisticsGroupPoint4);
            }
            if(res.data.statisticsGroupPoint5){
                list.push(res.data.statisticsGroupPoint5);
            }
            this.chartData = list;
            this.columns = res.data.columns;
            if(this.rpt==='1'){
                this.tableData = res.data.energyHourlyTableDatas;
            }else if(this.rpt==='2'){
                this.tableData = res.data.energyMonthlyTableDatas;
            }else if(this.rpt==='3'){
                this.tableData = res.data.energyYearlyTableDatas;
            }
            
            
            this.mobj = res.data.measureGrObject;
            this.drawChart();
        }else{
          if(res.errorCode == '00'){
                this.energyComparisonList = res.data.measurementGroupList;
                this.yearList = res.data.yearList;
                this.fiscalYear = res.data.fiscalYear;
                this.year = res.data.fiscalYear;
                this.rptKinds = res.data.rptKinds;
                this.rpt = this.rptKinds[0].no;
                this.ycategorys = res.data.ycategorys;
                this.mcategorys = res.data.mcategorys;
                this.dcategorys = res.data.dcategorys;
                this.monthList  = res.data.monthList;
                this.drawDefultChart();
                //实现自适应部分
                window.addEventListener("resize", () => this.resizeChart());
            } 
        }
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    handleChange(e){
      console.log("handleChange",e);
    },
    drawDefultChart(){
      let option =  this.getDefaultOption();
      // 基于准备好的dom，初始化echarts实例
      let energyComparisonChart = this.$echarts.init(document.getElementById("energyComparisonChart"));
      option && energyComparisonChart.setOption(option, true);
      this.resizeChart();
    },
    getDefaultOption(){
        let category =  ['04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03']; 
        if(parseInt(this.rpt)==3){
            //category = this.ycategorys;
        }else if(parseInt(this.rpt)==2){
            category = this.mcategorys;
        }else if(parseInt(this.rpt)==1){
            category = this.dcategorys;
        }
        let option = {
            backgroundColor: '#ffffff',
            title: {
                text: "对比图表",
                textStyle: {
                  color: "#7585cd",
                },
            },
            xAxis: {
                type: 'category',
                data: category,
            },
            yAxis: {
                type: 'value'
            },
            legend:{ },
            series: [{
                data: [0],
                type: 'line',
                smooth: true
            }]
        };
        return option;
    },
     resizeChart(){
      if(document.getElementById("energyComparisonChart")){ 
         this.$echarts.init(document.getElementById("energyComparisonChart")).resize();
      }
    },
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
  },
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
};
</script>

<style scoped>
#energyComparison {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
.ant-menu-submenu-title{
  border-bottom: 1px solid #eeeff3;
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background-color: #7682ce;
  margin-right: 20px;
}
.point-tree-box{width: 100%;height: 100%;padding:5px;border: 1px solid #d9d9d9 ;}
.point-tree{width: 100%;height:calc(100% - 5px);}
/* overflow: auto; */

.ec-box{
    width: 100%;
    height: 100%;
    overflow:auto ;
    padding: 10px;
}

.condition{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.condition:after{
    clear: both;
}

.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  
}
.display-center {
  display: flex;
  justify-content: center;
}
.display-column {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.display-column span {
  font-size: 14px;
}


.histogram {
  padding-top: 20px;
  box-shadow: 0 2px 15px #bdbcbc;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 10px;
}
.chart-box{
    /* background: #fff; */
    height: 100%;
    padding: 5px;
    margin: 10px 5px;
}
.table-box{
  height: 100%;
  padding: 5px;
  margin: 10px 5px;
  background: #fff;
}

.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  /* line-height: 160px; */
  background: #7682CE;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>